import React, {
  useState, useEffect, useRef, useCallback, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, formatISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import { fr } from 'date-fns/locale';
import { useSearchParams } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { AiOutlineSave, AiOutlineDelete } from 'react-icons/ai';
import { IoIosAdd } from 'react-icons/io';
import { BsCheck } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { downloadTransportEvent } from '../../../../utils';
import { EVENTS_GET } from '../../../../actions/types';
import { deleteEvent, updateEvent, createEvent } from '../../../../actions/event';
import withModalConfirm from '../../../../lib/withModalConfirm';
import ModalStandalone from '../../../../lib/ModalStandalone';
import Loader from '../../../Loader';
import file from '../../../../assets/images/icons/file-grey.svg';
import styles from './update-transport-event.module.scss';

import {
  InputText,
  InputFormatNumber,
  Textarea,
  StyledSelect,
  StyledCreatable,
  DatePicker,
} from '../../../../lib/HooksFormFields';

import SelectEventCases from '../SelectEventCases';
import Timepicker from '../Timepicker';

const defaultTimes = ['06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00',
  '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00'];

const UpdateTransportEvent = ({ handleClose, listOpts, confirm }) => {
  const dispatch = useDispatch();
  const modalClientRef = useRef();
  const { eventReducer, authReducer } = useSelector((store) => store);
  const { selectedAgency } = authReducer;
  const { agencies = [] } = eventReducer.list || {};

  const { events, changedAt, isLoading } = eventReducer;
  const [searchParams] = useSearchParams();
  const [event, setEvent] = useState(null);
  const [isLoadingDuplicate, setIsLoadingDuplicate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      trunkType: null,
    },
  });

  const {
    control: controlContact,
  } = useForm();

  const {
    control: controlLoadAgency,
  } = useForm();

  const contacts = (selectedAgency?.contacts || []).map((d) => ({
    label: d.name,
    value: d._id,
    tel: d.tel,
  }));

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchType = watch('type');
  const watchDate = watch('date');
  const watchCases = watch('cases');

  // SET EVENT
  useEffect(() => {
    if (!listOpts.transports
      || !listOpts.buyers
      || !listOpts.trunkTypes
      || !listOpts.carriers
    ) return;
    const paramId = searchParams.get('id');
    if (!events || events?.length <= 0 || !paramId || !listOpts || event) return;
    const foundEvent = events.find((evt) => evt._id === paramId);

    if (!foundEvent) return;
    const initialValues = {};
    if (foundEvent.startDate) {
      initialValues.date = new Date(foundEvent.startDate);
      initialValues.startDate = format(new Date(foundEvent.startDate), 'kk:mm');
    }
    if (foundEvent.endDate) initialValues.endDate = format(new Date(foundEvent.endDate), 'kk:mm');
    if (foundEvent.deliveryDate) initialValues.deliveryDate = new Date(foundEvent.deliveryDate);

    ///
    initialValues.transport = listOpts?.transports.find((d) => d.value === foundEvent.transport);
    initialValues.buyer = listOpts?.buyers.find((d) => d.value === foundEvent.buyer);
    if (foundEvent.orderDate) initialValues.orderDate = new Date(foundEvent.orderDate);
    initialValues.purchaseOrder = foundEvent.purchaseOrder || '';
    initialValues.trunk = foundEvent.trunk || '';
    initialValues.carrier = listOpts?.carriers
      .find((d) => d.value === foundEvent?.carrier?._id) || null;
    initialValues.quantity = foundEvent.quantity || '';
    initialValues.price = foundEvent.price || 0;
    initialValues.type = foundEvent.type;
    initialValues.trunkType = listOpts?.trunkTypes.find((d) => d.value === foundEvent.trunkType);
    initialValues.licensePlate = foundEvent.licensePlate;
    initialValues.zone = listOpts?.zones.find((d) => d.value === foundEvent.zone);
    initialValues.commentIntern = foundEvent.commentIntern || '';
    initialValues.commentExtern = foundEvent.commentExtern || '';
    ///

    if (foundEvent.loadDate) {
      initialValues.loadDate = new Date(foundEvent.loadDate);
      initialValues.loadHour = format(initialValues.loadDate, 'HH:mm', { locale: fr });
      if (foundEvent.loadDateEnd) {
        initialValues.loadHourEnd = format(new Date(foundEvent.loadDateEnd), 'HH:mm', { locale: fr });
      }
    }

    initialValues.loadLocation = {
      name: foundEvent.loadLocation.name || '',
      address: foundEvent.loadLocation.address || '',
      city: foundEvent.loadLocation.city || '',
      cp: foundEvent.loadLocation.cp || '',
    };
    initialValues.loadContact = foundEvent.loadContact || '';
    initialValues.loadTel = foundEvent.loadTel || '';
    //

    if (foundEvent.deliveryDate) {
      initialValues.deliveryDate = new Date(foundEvent.deliveryDate);
      initialValues.deliveryHour = format(initialValues.deliveryDate, 'HH:mm', { locale: fr });
      if (foundEvent.deliveryDateEnd) {
        initialValues.deliveryHourEnd = format(new Date(foundEvent.deliveryDateEnd), 'HH:mm', { locale: fr });
      }
    }

    initialValues.deliveryLocation = {
      name: foundEvent.deliveryLocation.name || '',
      address: foundEvent.deliveryLocation.address || '',
      city: foundEvent.deliveryLocation.city || '',
      cp: foundEvent.deliveryLocation.cp || '',
    };
    initialValues.deliveryContact = foundEvent.deliveryContact || '';
    initialValues.deliveryTel = foundEvent.deliveryTel || '';
    //

    // console.log(initialValues);
    // console.log(foundEvent.updatedAt);

    reset(initialValues);
    // setUpdatedTmsEvent(Date.now());
    setEvent(foundEvent);
  }, [
    changedAt,
    events,
    listOpts,
    searchParams,
  ]);

  // HANDLE DATE END
  useEffect(() => {
    const indexStart = defaultTimes.findIndex((d) => watchStartDate === d);
    const indexEnd = defaultTimes.findIndex((d) => watchEndDate === d);
    if (indexStart >= 0 && indexEnd >= 0 && indexStart >= indexEnd) {
      setValue('endDate', defaultTimes[indexStart + 1]);
    }
  }, [watchStartDate]);

  // HANDLE DATE START
  useEffect(() => {
    const indexStart = defaultTimes.findIndex((d) => watchStartDate === d);
    const indexEnd = defaultTimes.findIndex((d) => watchEndDate === d);
    if (indexStart >= 0 && indexEnd >= 0 && indexStart >= indexEnd) {
      setValue('startDate', defaultTimes[indexEnd - 1]);
    }
  }, [watchEndDate]);

  // HANDLE SET loadDate || deliveryDate
  const handleChangeDate = (date) => {
    if (!date || !event?.direction) return;
    if (event.direction === 'departure') {
      setValue('loadDate', date);
    } else if (event.direction === 'return') {
      setValue('deliveryDate', date);
    }
  };

  function deleteTransportEvent() {
    const paramId = searchParams.get('id');
    if (!paramId) return;
    const fbDelete = async () => {
      const res = await deleteEvent(dispatch, { _id: paramId });
      if (res.statusText === 'OK') handleClose();
    };

    confirm(fbDelete, 'Êtes vous sûr de vouloir supprimer cet événement transport ?');
  }

  async function duplicateTransportEvent() {
    const {
      _id,
      __v,
      updatedAt,
      createdAt,
      ...duplicateEvent
    } = event;
    duplicateEvent.cases = duplicateEvent.cases.map((d) => d._id);
    setIsLoadingDuplicate(true);
    const res = await createEvent(dispatch, duplicateEvent);
    setTimeout(() => {
      setIsLoadingDuplicate(false);
      if (res.statusText === 'OK') handleClose();
    }, 600);
  }

  async function onSubmit(data) {
    let startDate = null;
    let endDate = null;
    if (data.date && data.startDate) {
      startDate = new Date(data.date);
      const [hoursStart = 0, minutesStart = 0] = data.startDate.split(':');
      startDate.setHours(parseInt(hoursStart, 10));
      startDate.setMinutes(parseInt(minutesStart, 10));
      startDate.setSeconds(0);
    }
    if (data.date && data.endDate) {
      endDate = new Date(data.date);
      const [hoursEnd = 0, minutesEnd = 0] = data.endDate.split(':');
      endDate.setHours(parseInt(hoursEnd, 10));
      endDate.setMinutes(parseInt(minutesEnd, 10));
      endDate.setSeconds(0);
    }

    // IF LESS THAN 30 MINUTES INTERVAL SET
    // ENDDATE TO 30 MINUTES AFTER START
    if (endDate && (endDate.getTime() - startDate.getTime()) < (1800 * 1000)) {
      endDate = new Date(startDate.getTime() + 30 * 60000);
    }

    let loadDate = null;
    let loadDateEnd = null;
    if (data.loadDate) {
      loadDate = new Date(data.loadDate);
      if (data.loadHour) {
        const [hoursLoad = 0, minutesLoad = 0] = data.loadHour.split(':');
        loadDate.setHours(parseInt(hoursLoad, 10));
        loadDate.setMinutes(parseInt(minutesLoad, 10));
        loadDate.setSeconds(0);
      }
      if (data.loadHourEnd) {
        loadDateEnd = new Date(data.loadDate);
        const [hoursLoad = 0, minutesLoad = 0] = data.loadHourEnd.split(':');
        loadDateEnd.setHours(parseInt(hoursLoad, 10));
        loadDateEnd.setMinutes(parseInt(minutesLoad, 10));
        loadDateEnd.setSeconds(0);
      }
    }

    let deliveryDate = null;
    let deliveryDateEnd = null;
    if (data.deliveryDate) {
      deliveryDate = new Date(data.deliveryDate);
      if (data.deliveryHour) {
        const [hoursDelivery = 0, minutesDelivery = 0] = data.deliveryHour.split(':');
        deliveryDate.setHours(parseInt(hoursDelivery, 10));
        deliveryDate.setMinutes(parseInt(minutesDelivery, 10));
        deliveryDate.setSeconds(0);
      }
      if (data.deliveryHourEnd) {
        deliveryDateEnd = new Date(data.deliveryDate);
        const [hoursLoad = 0, minutesLoad = 0] = data.deliveryHourEnd.split(':');
        deliveryDateEnd.setHours(parseInt(hoursLoad, 10));
        deliveryDateEnd.setMinutes(parseInt(minutesLoad, 10));
        deliveryDateEnd.setSeconds(0);
      }
    }

    const { date, ...newEvent } = {
      ...data,
      _id: event._id,
      agency: selectedAgency._id,
      carrier: data?.carrier?.value || null,
      transport: data?.transport?.value || null,
      buyer: data?.buyer?.value || null,
      trunkType: data?.trunkType?.value || null,
      type: data?.type || null,
      zone: data?.zone?.value || null,
      trunk: data.trunk || null,
      commentIntern: data.commentIntern || null,
      commentExtern: data.commentExtern || null,
      loadDate: loadDate ? formatISO(loadDate) : null,
      loadDateEnd: loadDateEnd ? formatISO(loadDateEnd) : null,
      deliveryDate: deliveryDate ? formatISO(deliveryDate) : null,
      deliveryDateEnd: deliveryDateEnd ? formatISO(deliveryDateEnd) : null,
      endDate: endDate ? formatISO(endDate) : null,
      startDate: startDate ? formatISO(startDate) : null,
    };

    if (data.cases) {
      newEvent.cases = [
        ...data.cases.map((c) => c.value),
        ...event.cases.map((c) => c._id),
      ];
    }

    setIsLoadingUpdate(true);
    const res = await updateEvent(dispatch, newEvent);
    setTimeout(() => {
      setIsLoadingUpdate(false);
      if (res.statusText === 'OK') handleClose();
    }, 600);
  }

  const handleDeleteNewClient = useCallback((index) => {
    if (!watchCases || watchCases?.length === 0) return;
    const udpatedCases = JSON.parse(JSON.stringify(watchCases));
    udpatedCases.splice(index, 1);
    setValue('cases', udpatedCases);
  }, [watchCases]);

  const handleSharePdf = useCallback(async () => {
    let subject = 'LVTec';
    if (event.purchaseOrder) subject += ` - Bon de commande N° ${event.purchaseOrder}`;
    if (event?.type?.value) subject += ` | ${event.type.value}`;
    else subject += ' | ';
    subject += ` ${event?.loadLocation?.city?.toUpperCase() || '***'}`;
    subject += ` - ${event?.deliveryLocation?.city?.toUpperCase() || '***'}`;
    if (event.trunkType) subject += ` ${event.trunkType}`;
    if (event?.cases[0]) subject += ` ${event?.cases[0].ref}`;

    try {
      const config = {
        headers: {
          Authorization: `${localStorage.getItem('token')}`,
        },
      };
      const response = await fetch(`${process.env.REACT_APP_API_URL}/transport/${event._id}/link`, config);
      const data = await response.json();
      const mail = document.createElement('a');

      const body = `Bonjour,%0D%0AVeuillez trouver ci-dessous notre Bon de commande.%0D%0A%0D%0A ${data.link}%0D%0A%0D%0AVous en souhaitant bonne réception,%0D%0ACordialement,%0D%0A`;

      mail.href = `mailto:${event?.carrier?.email}?body=${body}&subject=${subject}`;
      mail.click();
    } catch (error) {
      console.log(error);
    }
  }, [event]);

  const handlePressEnter = (e) => {
    if (e.key === 'Enter' && e.target.tagName !== 'TEXTAREA') {
      handleSubmit(onSubmit)();
    }
  };

  const getAddressString = (address) => {
    const addressInfos = [];
    let strAddress = '';
    if (address.number) addressInfos.push(address.number);
    if (address.street) addressInfos.push(address.street);
    if (address.complement) addressInfos.push(`(${address.complement})`);
    strAddress = addressInfos.join(' ');
    if (address.postal_code) strAddress += strAddress ? `, ${address.postal_code}` : address.postal_code;
    return strAddress;
  };

  const preFilledAddress = (agencyId, key) => {
    const { address } = agencies.find((d) => d.value === agencyId);
    const strAddress = getAddressString(address);
    if (strAddress) setValue(`${key}.address`, strAddress);

    if (address.city) setValue(`${key}.city`, address.city);
    return address;
  };

  // function getMinTime() {
  //   const findIndex = defaultTimes.findIndex((d) => d === watchStartDate);
  //   return defaultTimes[findIndex + 1];
  // }

  // function getMaxTime() {
  //   const findIndex = defaultTimes.findIndex((d) => d === watchEndDate);
  //   return defaultTimes[findIndex - 1];
  // }

  const optsAgencies = useMemo(
    () => (agencies || [])
      .sort((a, b) => a.label.localeCompare(b.label)),
    [agencies],
  );

  const disabledPDFGeneration = useMemo(() => {
    if (!event) return true;
    const bool = !!(
      event.type
      && event.buyer
      && event.carrier
      && typeof event.quantity === 'number'
      && typeof event.price === 'number' && event.price !== 0
      && event.trunkType
      && event.loadDate
      && event.deliveryDate
      && event.purchaseOrder && /^AC[a-zA-Z-0-9]{6,8}$/.test(event.purchaseOrder)
      && (
        (
          event.direction === 'departure'
          && event.deliveryLocation?.address
        ) || (
          event.direction === 'return'
          && event.loadLocation?.address
        )
      )
    );
    return !bool;
  }, [event]);

  return (
    <>
      <ModalStandalone ref={modalClientRef} zIndex={2010}>
        <div className={styles.modalCases}>
          <div className={styles.addCases}>
            <label>Ajouter un client</label>
            <div className={styles.containerField}>
              <SelectEventCases
                control={control}
                options={listOpts?.cases}
              />
            </div>
          </div>
          <div className={styles.containerButtons}>
            <button onClick={() => modalClientRef.current.close()}>Valider</button>
          </div>
        </div>
      </ModalStandalone>
      <div onKeyDown={handlePressEnter} className={styles.form}>
        <div className={styles.back}>
          <button className='light' onClick={() => handleClose()}>
            <span className='icon'><FiArrowLeft size={'18px'} /></span>
            <span>Retour</span>
          </button>
        </div>
        {isLoading.includes(EVENTS_GET)
          ? <Loader />
          : (
          <>
            <div className={styles.firstRow}>
              <div className={styles.containerField}>
                <div className={styles.dateFields}>
                  {event?.direction && (
                    <div className={`${styles.direction} ${event.direction === 'Départ' ? styles.checkOut : styles.checkIn}`}>
                      <p>{listOpts?.directions.find((d) => d.value === event?.direction)?.label}</p>
                    </div>
                  )}
                  <div className={styles.date}>
                    <DatePicker
                      name='date'
                      control={control}
                      dateFormat='eeee dd LLLL'
                      placeholder='Choisir une date'
                      className='discreet'
                      handleChange={(date) => handleChangeDate(date)}
                      inline
                    />
                  </div>
                  <div className={styles.time}>
                    <Timepicker
                      name='startDate'
                      times={defaultTimes.slice(0, defaultTimes.length - 1)}
                      placeholder={defaultTimes[0]}
                      // maxValue={getMaxTime()}
                      control={control}
                      handleChange={(hour) => {
                        const indexOf = defaultTimes.findIndex((d) => d === hour);
                        if (indexOf !== -1) {
                          setValue('endDate', defaultTimes[indexOf + 1]);
                        }
                      }}
                    />
                  </div>
                  <span>&nbsp;-&nbsp;</span>
                  <div className={styles.time}>
                    <Timepicker
                      name='endDate'
                      times={defaultTimes.slice(1, defaultTimes.length)}
                      placeholder={defaultTimes[1]}
                      // minValue={getMinTime()}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div
                  className={`${styles.containerField} ${styles.type}`}
                  style={{
                    color: watchType?.color,
                  }}
                >
                  <StyledSelect
                    name="type"
                    control={control}
                    widthLabel="105px"
                    placeholder="Type de transport"
                    inline
                    options={listOpts?.types}
                    highlight={!event?.type}
                  />
                </div>
                <button
                  disabled={disabledPDFGeneration}
                  className={disabledPDFGeneration ? 'disabled' : ''}
                  onClick={
                  () => {
                    if (disabledPDFGeneration) return;
                    downloadTransportEvent(
                      event._id,
                      `export-transport-${format(watchDate || new Date(), 'HH:mm', { locale: fr })}`,
                    );
                  }}
                >
                  Générer un document
                </button>
                {event?.carrier?.email && <button onClick={() => handleSharePdf()}>
                  <MdOutlineEmail size={20} />
                </button>}
              </div>
            </div>
            <div className={styles.clients}>
              <div className={styles.list}>
                {event?.cases.map((d) => (
                  <div className={styles.client} key={`client-${d._id}`}>
                    <h4>
                      {d.client} <span> / {d.ref}</span>
                      {event?.number && (
                        <span className={styles.linkedTaskNumber}>
                          &nbsp;/&nbsp;<img src={file} alt={event.number} />
                          {` ${event?.number}`}
                        </span>
                      )}
                    </h4>
                    <div className={styles.contact}>
                      {d?.name && (
                        <>
                          <p>Nom du chantier</p>
                          <p>{d?.name}</p>
                        </>
                      )}
                      <p>Nom du chargé d’affaire</p>
                      <p>{`${d?.user?.profile?.lastName || '---'} ${d?.user?.profile?.firstName || '---'}`}</p>
                      {d?.user?.profile?.tel && <>
                        <p>Numéro de téléphone</p>
                        <p>{d?.user?.profile?.tel}</p>
                      </>}
                    </div>
                  </div>
                ))}
                {watchCases?.length > 0 && (
                  <div className={styles.newCases}>
                    <label>Nouveau clients : </label>
                    {watchCases.map((field, index) => (
                      <div key={field.value} className={styles.newCase}>
                        <p>{field.label}</p>
                        <span className={styles.delete}
                        onClick={(() => handleDeleteNewClient(index))}>
                          <AiOutlineDelete />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <button className='third' onClick={() => modalClientRef.current.open()}>
                <span className='icon'><IoIosAdd size={14} /></span>
                <span> Ajouter un client</span>
              </button>
            </div>
            <div className={styles.transportDetail}>
              <h3>Informations générales</h3>
              <div className={styles.sellPrice}>
                <p>Prix de vente</p>
                <p>{event?.sellPrice || ''}</p>
              </div>
              <div className={styles.row}>
                {watchType?.value === 'TRANSFERT DE PARC' && (
                  <div className={styles.containerField}>
                    <StyledSelect
                      name="destAgency"
                      control={control}
                      label='Agence de destination'
                      widthLabel="135px"
                      inline
                      options={optsAgencies}
                      isClearable
                      handleChange={(opt) => preFilledAddress(
                        opt.value,
                        event?.direction === 'return' ? 'loadLocation' : 'deliveryLocation',
                      )}
                    />
                  </div>
                )}
              </div>
              <div className={styles.row}>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="transport"
                    control={control}
                    label="Transport"
                    widthLabel="135px"
                    placeholder=""
                    inline
                    isClearable
                    options={listOpts?.transports}
                  />
                </div>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="buyer"
                    control={control}
                    label="Acheteur"
                    placeholder=""
                    inline
                    isClearable
                    options={listOpts?.buyers || []}
                    highlight={!event?.buyer}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.containerField}>
                  <DatePicker
                    name='orderDate'
                    control={control}
                    dateFormat='dd/MM/yyyy'
                    label='Date de commande'
                    widthLabel="135px"
                    placeholder='Date de commande'
                    className='discreet'
                    inline
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="purchaseOrder"
                    placeholder='AC00000000'
                    control={control}
                    label="Bon de commande fournisseur"
                    inline
                    highlight={
                      !event?.purchaseOrder || !/^AC[a-zA-Z-0-9]{6,8}$/.test(event?.purchaseOrder)
                    }
                  />
                </div>
              </div>
              <div className={styles.containerField}>
                <InputText
                  name="trunk"
                  control={control}
                  label="Informations transport"
                  widthLabel="135px"
                  placeholder="Informations transport"
                  inline
                />
              </div>
              <div className={styles.row}>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="carrier"
                    control={control}
                    label="Nom du transporteur"
                    widthLabel="135px"
                    placeholder="Nom du transporteur"
                    isSearchable={true}
                    isClearable
                    inline
                    options={listOpts?.carriers}
                    highlight={!event?.carrier}
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="quantity"
                    control={control}
                    label="Quantité"
                    widthLabel="55px"
                    placeholder=""
                    inline
                    highlight={typeof event?.quantity !== 'number'}
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="price"
                    control={control}
                    label="Prix"
                    widthLabel="35px"
                    placeholder=""
                    inline
                    highlight={
                      typeof event?.price !== 'number' || event?.price === 0
                    }
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="trunkType"
                    control={control}
                    label="Type de camion"
                    widthLabel="135px"
                    placeholder="Type de camion"
                    isClearable
                    inline
                    options={listOpts?.trunkTypes}
                    highlight={!event?.trunkType}
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="licensePlate"
                    control={control}
                    label="Plaque d'immatriculation"
                    widthLabel="160px"
                    placeholder="Plaque d'immatriculation"
                    inline
                  />
                </div>
                <div className={styles.containerField}>
                  <StyledCreatable
                    name="zone"
                    control={control}
                    label="Zone"
                    widthLabel="35px"
                    placeholder="Zone"
                    isClearable
                    inline
                    options={listOpts?.zones}
                  />
                </div>
              </div>
              <div className={`${styles.row} ${styles.comment}`}>
                <div className={styles.containerField}>
                  <Textarea
                    name="commentIntern"
                    control={control}
                    widthLabel="135px"
                    label="Commentaire interne"
                    inline
                  />
                </div>
              </div>
              <div className={`${styles.row} ${styles.comment}`}>
                <div className={styles.containerField}>
                  <Textarea
                    name="commentExtern"
                    control={control}
                    widthLabel="135px"
                    label="Commentaire externe"
                    inline
                  />
                </div>
              </div>
            </div>
            <div className={styles.load}>
              <h3>Chargement</h3>
              <div className={`${styles.containerField} ${styles.loadDate}`}>
                <DatePicker
                  name='loadDate'
                  control={control}
                  dateFormat='eeee dd LLLL'
                  label='Date de chargement'
                  widthLabel="135px"
                  placeholder='Date de chargement'
                  className='discreet'
                  inline
                  highlight={!event?.loadDate}
                  handleChange={() => {
                    const loadHour = getValues('loadHour');
                    if (!loadHour) {
                      setValue('loadHour', defaultTimes[2]);
                    }
                  }}
                />
                <div className={styles.time}>
                  <Timepicker
                    name='loadHour'
                    times={defaultTimes.slice(0, defaultTimes.length - 1)}
                    placeholder={defaultTimes[0]}
                    // maxValue={getMaxTime()}
                    control={control}
                  />
                </div>
                <div className={styles.time}>
                  <Timepicker
                    name='loadHourEnd'
                    times={defaultTimes.slice(0, defaultTimes.length - 1)}
                    placeholder={defaultTimes[0]}
                    // maxValue={getMaxTime()}
                    control={control}
                  />
                </div>
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="prefillLoadAddress"
                  control={controlLoadAgency}
                  label="Pré-remplir le lieu"
                  placeholder="Sélectionner l'agence..."
                  inline
                  isClearable
                  isSearchable
                  widthLabel="135px"
                  options={optsAgencies}
                  handleChange={(opt) => {
                    if (!opt) return;
                    const address = preFilledAddress(opt.value, 'loadLocation');
                    setValue('loadLocation.name', opt?.label || '');
                    setValue('loadLocation.cp', address.postal_code || '');
                  }}
                />
              </div>
              <div className={styles.location}>
                <p>Lieu de chargement</p>
                <div className={styles.locationFields}>
                  <div className={styles.containerField}>
                    <InputText
                      name="loadLocation.name"
                      control={control}
                      widthLabel="105px"
                      placeholder="Nom"
                    />
                  </div>
                  <div className={styles.containerField}>
                    <InputText
                      name="loadLocation.address"
                      control={control}
                      widthLabel="105px"
                      placeholder="Adresse"
                      highlight={
                        event?.direction === 'return' && !event?.loadLocation?.address
                      }
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.containerField}>
                      <InputText
                        name="loadLocation.city"
                        control={control}
                        widthLabel="105px"
                        placeholder="Ville"
                      />
                    </div>
                    <div className={styles.containerField}>
                      <InputText
                        name="loadLocation.cp"
                        control={control}
                        widthLabel="105px"
                        placeholder="CP"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="prefillLoadContact"
                    control={controlContact}
                    label="Pré-remplir le contact"
                    placeholder="Sélectionner le contact sur place..."
                    inline
                    isClearable
                    widthLabel="135px"
                    options={contacts}
                    handleChange={(opt) => {
                      setValue('loadContact', opt?.label || '');
                      setValue('loadTel', opt?.tel || '');
                    }}
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="loadContact"
                    control={control}
                    widthLabel="135px"
                    inline
                    label="Contact sur place"
                  />
                </div>
                <div className={styles.containerField}>
                  <InputFormatNumber
                    name="loadTel"
                    label="Téléphone du contact"
                    inline
                    control={control}
                    widthLabel="135px"
                    format="## ## ## ## ##"
                  />
                </div>
              </div>
            </div>
            <div className={styles.delivery}>
              <h3>Livraison</h3>
              <div className={`${styles.containerField} ${styles.deliveryDate}`}>
                <DatePicker
                  name='deliveryDate'
                  control={control}
                  dateFormat='eeee dd LLLL'
                  label='Date de livraison'
                  widthLabel="135px"
                  placeholder='Date de livraison'
                  className='discreet'
                  inline
                  highlight={!event?.deliveryDate}
                  handleChange={() => {
                    const deliveryHour = getValues('deliveryHour');
                    if (!deliveryHour) {
                      setValue('deliveryHour', defaultTimes[2]);
                    }
                  }}
                />
                <div className={styles.time}>
                  <Timepicker
                    name='deliveryHour'
                    times={defaultTimes.slice(0, defaultTimes.length - 1)}
                    placeholder={defaultTimes[0]}
                    // maxValue={getMaxTime()}
                    control={control}
                  />
                </div>
                <div className={styles.time}>
                  <Timepicker
                    name='deliveryHourEnd'
                    times={defaultTimes.slice(0, defaultTimes.length - 1)}
                    placeholder={defaultTimes[0]}
                    // maxValue={getMaxTime()}
                    control={control}
                  />
                </div>
              </div>
              <div className={styles.containerField}>
                <StyledSelect
                  name="prefillDeliveryAddress"
                  control={controlLoadAgency}
                  label="Pré-remplir le lieu"
                  placeholder="Sélectionner l'agence..."
                  inline
                  isClearable
                  isSearchable
                  widthLabel="135px"
                  options={optsAgencies}
                  handleChange={(opt) => {
                    if (!opt) return;
                    const address = preFilledAddress(opt.value, 'deliveryLocation');
                    setValue('deliveryLocation.name', opt?.label || '');
                    setValue('deliveryLocation.cp', address.postal_code || '');
                  }}
                />
              </div>
              <div className={styles.location}>
                <p>Lieu de livraison</p>
                <div className={styles.locationFields}>
                  <div className={styles.containerField}>
                    <InputText
                      name="deliveryLocation.name"
                      control={control}
                      widthLabel="105px"
                      placeholder="Nom"
                    />
                  </div>
                  <div className={styles.containerField}>
                    <InputText
                      name="deliveryLocation.address"
                      control={control}
                      widthLabel="105px"
                      placeholder="Adresse"
                      highlight={
                        event?.direction === 'departure' && !event?.deliveryLocation?.address
                      }
                    />
                  </div>
                  <div className={styles.row}>
                    <div className={styles.containerField}>
                      <InputText
                        name="deliveryLocation.city"
                        control={control}
                        widthLabel="105px"
                        placeholder="Ville"
                      />
                    </div>
                    <div className={styles.containerField}>
                      <InputText
                        name="deliveryLocation.cp"
                        control={control}
                        widthLabel="105px"
                        placeholder="CP"
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.containerField}>
                  <StyledSelect
                    name="prefillDelivery"
                    control={controlContact}
                    label="Pré-remplir le contact"
                    placeholder="Sélectionner le contact sur place..."
                    inline
                    isClearable
                    options={contacts}
                    handleChange={(opt) => {
                      setValue('deliveryContact', opt?.label || '');
                      setValue('deliveryTel', opt?.tel || '');
                    }}
                  />
                </div>
                <div className={styles.containerField}>
                  <InputText
                    name="deliveryContact"
                    control={control}
                    widthLabel="135px"
                    inline
                    label="Contact sur place"
                  />
                </div>
                <div className={styles.containerField}>
                  <InputFormatNumber
                    name="deliveryTel"
                    label="Téléphone du contact"
                    inline
                    control={control}
                    widthLabel="135px"
                    format="## ## ## ## ##"
                  />
                </div>
              </div>
            </div>
          </>
          )}
      <div className={styles.transportActions}>
        <button onClick={handleSubmit(onSubmit)}>
          {isLoadingUpdate
            ? <span className='icon loader'><Loader size={14} className='secondary' /></span>
            : <>
              {!isDirty
                ? <span className='icon'><BsCheck size={'24px'} /></span>
                : <span className='icon'><AiOutlineSave size={'20px'} /></span>
              }
            </>
          }
          <span>Valider</span>
        </button>
        <button
          className='third'
          onClick={() => duplicateTransportEvent()}
        >
          {isLoadingDuplicate
            && <span className='icon loader'><Loader size={14} className='third' /></span>
          }
          <span>Dupliquer</span>
        </button>
        <button
          className={styles.btnDelete}
          onClick={() => deleteTransportEvent()}
        >
          Supprimer
        </button>
      </div>
      </div>
    </>
  );
};

export default withModalConfirm(UpdateTransportEvent);
